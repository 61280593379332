import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Hero from '../components/hero'
import Layout from '../components/layout'
import PaypalCheckout from '../components/Paypal'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Slider from '@material-ui/core/Slider'

const CLIENT = {
    sandbox: 'Ad8WbM_471-LfclF9B-8SYwwRbmwAQNYiJe28KLH6PK2DDzLxRsN2fgCvbNnTO107v00uH9m5yqJYiHp',
    production: 'AYdXzRxj9Fo-NGXVgSBuG3joCZareEXuf3OIzj3JVQ3HmBOGHiMcpwmPdGNGE-8xF0GQh_sRijp2ZJ6t'
};
  
const ENV = process.env.NODE_ENV === 'production'
    ? 'production'
    : 'sandbox';


const styles = {
    root: {
        width: 300,
    },
    slider: {
        padding: '22px 0px',
    },
};

class DonsIndex extends React.Component {
    
    state = {
        value: 10,
    };
    
    handleChange = (event, value) => {
        this.setState({ value });
    };

  render() {
    const { value } = this.state;
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const [author] = get(this, 'props.data.allContentfulDonsPage.edges')

    const onSuccess = (payment) =>
        console.log('Successful payment!', payment);

    const onError = (error) =>
        console.log('Erroneous payment OR failed to load script!', error);

    const onCancel = (data) =>
        console.log('Cancelled payment!', data);


    return (
      <Layout location={this.props.location} >
        <div style={{ background: '#fff' }}>
          <Helmet title={siteTitle} />
          <Hero data={author.node} />
          <div className="wrapper">
            <div
                style={{
                    textAlign: 'center',
                    fontSize: '1.3em'
                }}>
                <div style={{marginBottom: "50px"}}>{author.node.punchline}</div>
                <div className="sliderDons">
                    <Typography id="label">{value} €</Typography>
                    <Slider
                        value={value}
                        min={0}
                        max={500}
                        step={0.5}
                        onChange={this.handleChange}
                        />
                </div>
                <PaypalCheckout
                    client={CLIENT}
                    env={ENV}
                    commit={true}
                    currency={'EUR'}
                    total={value}
                    onSuccess={onSuccess}
                    onError={onError}
                    onCancel={onCancel}
                    />
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default DonsIndex

export const pageQuery = graphql`
  query DonsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulDonsPage {
      edges {
        node {
          title
          shortBio {
            shortBio
          }
          punchline
          heroImage: headerImage {
            fluid(
              resizingBehavior: PAD
              background: "rgb:000000"
            ) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
        }
      }
    }
  }
`
